import logoVn from '../../../../images/RBLX_TheHunt_Localized_Logos_The_Hunt_Loc_VN_Logo_400x250px.svg';
import logoTr from '../../../../images/RBLX_TheHunt_Localized_Logos_The_Hunt_Loc_TR_Logo_400x250px.svg';
import logoTh from '../../../../images/RBLX_TheHunt_Localized_Logos_The_Hunt_Loc_TH_Logo_400x250px.svg';
import logoPtbr from '../../../../images/RBLX_TheHunt_Localized_Logos_The_Hunt_Loc_PTBR_Logo_400x250px.svg';
import logoPl from '../../../../images/RBLX_TheHunt_Localized_Logos_The_Hunt_Loc_PL_Logo_400x250px.svg';
import logoKo from '../../../../images/RBLX_TheHunt_Localized_Logos_The_Hunt_Loc_KO_Logo_400x250px.svg';
import logoJp from '../../../../images/RBLX_TheHunt_Localized_Logos_The_Hunt_Loc_JP_Logo_400x250px.svg';
import logoIt from '../../../../images/RBLX_TheHunt_Localized_Logos_The_Hunt_Loc_IT_Logo_400x250px.svg';
import logoId from '../../../../images/RBLX_TheHunt_Localized_Logos_The_Hunt_Loc_ID_Logo_400x250px.svg';
import logoFr from '../../../../images/RBLX_TheHunt_Localized_Logos_The_Hunt_Loc_FR_Logo_400x250px.svg';
import logoEs from '../../../../images/RBLX_TheHunt_Localized_Logos_The_Hunt_Loc_ES_Logo_400x250px.svg';
import logoEn from '../../../../images/RBLX_TheHunt_Localized_Logos_The_Hunt_Loc_EN_Logo_400x250px.svg';
import logoDe from '../../../../images/RBLX_TheHunt_Localized_Logos_The_Hunt_Loc_DE_Logo_400x250px.svg';
import logoCht from '../../../../images/RBLX_TheHunt_Localized_Logos_The_Hunt_Loc_CHT_Logo_400x250px.svg';
import logoChs from '../../../../images/RBLX_TheHunt_Localized_Logos_The_Hunt_Loc_CHS_Logo_400x250px.svg';

export default {
  vi_vn: logoVn,
  tr_tr: logoTr,
  th_th: logoTh,
  pt_br: logoPtbr,
  pl_pl: logoPl,
  ko_kr: logoKo,
  ja_jp: logoJp,
  it_it: logoIt,
  id_id: logoId,
  fr_fr: logoFr,
  es_es: logoEs,
  en_us: logoEn,
  de_de: logoDe,
  zh_tw: logoCht,
  zh_cn: logoChs
};
